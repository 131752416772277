import { NgModule, DEFAULT_CURRENCY_CODE } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgScrollbarModule } from 'ngx-scrollbar';
import * as TablerIcons from 'angular-tabler-icons/icons';
import { TablerIconsModule } from 'angular-tabler-icons';
import { FilterPipe } from './pipe/filter.pipe';
import { RouterModule } from '@angular/router';
import { CardOperacoesComponent } from './components/card-operacoes/card-operacoes.component';
import { CardMoneyComponent } from './components/card-money/card-money.component';
import { MatPaginatorIntl } from "@angular/material/paginator";
import { getPortuguesePaginatorIntl } from './extensions/paginator.extensions';
import { MatNativeDateModule } from '@angular/material/core';
import { NgApexchartsModule } from 'ng-apexcharts';
import { ToastrModule } from 'ngx-toastr';
import { CurrencyMaskConfig, CurrencyMaskModule, CURRENCY_MASK_CONFIG } from 'ng2-currency-mask';
import { TelefonePipe } from './pipe/telefone.pipe';
import { CpfCnpjPipe } from './pipe/cpfcnpj.pipe';
import { NgxMaskDirective } from 'ngx-mask';
import { BaseChartDirective } from 'ng2-charts';


export const CustomCurrencyMaskConfig: CurrencyMaskConfig = {
  align: "left",
  allowNegative: true,
  decimal: ",",
  precision: 2,
  prefix: "",
  suffix: "",
  thousands: ".",
};


@NgModule({
    declarations: [FilterPipe,TelefonePipe,CpfCnpjPipe],
    imports: [
        CommonModule,
        MaterialModule,
        FormsModule,
        RouterModule,
        ReactiveFormsModule,
        ToastrModule.forRoot(), // ToastrModule added
        TablerIconsModule.pick(TablerIcons),
        NgScrollbarModule,
        CardOperacoesComponent,
        CardMoneyComponent,
        MatNativeDateModule,
        NgApexchartsModule,
        CurrencyMaskModule,
        NgxMaskDirective,
        BaseChartDirective
    ],
    exports: [
        CommonModule,
        MaterialModule,
        FormsModule,
        RouterModule,
        ReactiveFormsModule,
        TablerIconsModule,
        NgScrollbarModule,
        CardOperacoesComponent,
        CardMoneyComponent,
        MatNativeDateModule,
        NgApexchartsModule,
        CurrencyMaskModule,
        TelefonePipe,
        CpfCnpjPipe,
        NgxMaskDirective,
        BaseChartDirective
    ],
    providers: [{
        useValue: getPortuguesePaginatorIntl(),
        provide: MatPaginatorIntl
    },
    { provide: CURRENCY_MASK_CONFIG, useValue: CustomCurrencyMaskConfig },
      { provide: DEFAULT_CURRENCY_CODE, useValue: '' },
  ]
})
export class SharedModule { }
