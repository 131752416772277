import { NavItem } from './nav-item';

export const navItemsAdministradorPerseus: NavItem[] = [
  // {
  //   navCap: 'Início',
  // },
  // {
  //   displayName: 'Dashboard',
  //   iconName: 'layout-dashboard',
  //   route: '/dashboard',
  // },
  {

    displayName: 'Administrador',
    navCap: 'Administrador',
    navCapMobile: 'Admin',
  },
  {
    displayName: 'Clientes',
    iconName: 'users',
    route: '/clientes',
  },
  {
    displayName: 'Contas correntes',
    iconName: 'building-bank',
    route: '/conta-corrente/contas-correntes',
  },

  /* {
     displayName: 'Transações',
     iconName: 'credit-card-pay',
     route: '/cobranca/transacoes',
   }, */
  {
    displayName: 'Saques',
    iconName: 'receipt-2',
    route: '/conta-corrente/saque',
  },
  {
    displayName: 'Conciliações',
    iconName: 'cash',
    children: [
      {
        displayName: 'Auditoria',
        iconName: 'point',
        route: 'conciliacoes/auditoria',
      },
      {
        displayName: 'Transações',
        iconName: 'point',
        route: 'conciliacoes/conciliacoes',
      }
    ],
  }, 
  {
    displayName: 'Contas bancárias',
    iconName: 'premium-rights',
    route: 'configuracoes/contas-bancarias',
  },
  {
    displayName: 'Configurações',
    iconName: 'settings',
    route: 'configuracoes/administrador',
  },
  {
    displayName: 'Instituiçoes Financeiras',
    iconName: 'home',
    route: 'instituicao-financeira',
  },
];
